<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <create-edit-aula-dialog :aula="aula" v-model="editDialogOpened"
                             @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <!--div>Seu nível é: {{ user.nivel }}</div-->
      <aulas-data-table ref="dataTable"
                        @click:edit="editar"
                        @click:remove="remover"
                        :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import AulasDataTable from "../../components/shared/datatables/AulasDataTable";
import BaseView from "../BaseView";
import CreateEditAulaDialog from "../../components/shared/dialogs/CreateEditAulaDialog";
import {remove} from "../../api/aulas";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {CreateEditAulaDialog, BaseView, AulasDataTable},
  data() {
    return {
      aula: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Descrição',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'Preço',
          column: 'preco',
          type: 'number',
          prefix: 'R$'
        },
        {
          name: 'Qtd. Dias',
          column: 'qtdDias',
          type: 'number'
        }
      ]
    }
  },
  methods: {
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.aula = {};
      this.editDialogOpened = true;
    },
    editar(aula) {
      this.aula = JSON.parse(JSON.stringify(aula));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Aula removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover à aula.`, {
          timeout: 6000
        })
      }
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated', 'menu', 'authorizedLinks'])/*,
    nivel: {
      get() {
        return this.user.nivel;
      }
    }*/
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>